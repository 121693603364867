import { FC } from 'react';

import styles from './JitSidePanel.module.scss';

import { Cross } from 'assets';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import commonStyles from 'themes/common.module.scss';

interface Props {
  children: React.ReactNode;
  closePanel: () => void;
  title: string;
  variant?: 'light' | 'dark';
}

const variantToStyle = {
  light: styles.light,
  dark: styles.dark,
};

export const JitSidePanel: FC<Props> = ({ children, closePanel, title, variant = 'dark' }) => {
  const classes = `${styles.body} ${variantToStyle[variant]}`;

  return (
    <div className={classes} data-testid='JitSidePanel' onClick={(e) => e.stopPropagation()} role='presentation'>
      <div className={styles.headerWrapper}>

        <div className={styles.titleWrapper}>
          <JitText color={colors.lightGray} size='s' text={title} />

          <div className={styles.titleRightPart}>
            <JitIcon icon={Cross} onClick={closePanel} size={20} />
          </div>
        </div>
      </div>

      <JitDivider />

      {/* Paper Content */}

      <div className={`${styles.content} ${commonStyles.scrollInnerShadow}`}>
        {children}
      </div>
    </div>
  );
};
