import Menu from '@mui/material/Menu';
import { FC } from 'react';

import styles from '../../SavedFiltersDropdown.module.scss';
import { DeleteSavedFilter } from '../DeleteSavedFilter/DeleteSavedFilter';
import { EditSavedFilter } from '../EditSavedFilter/EditSavedFilter';
import { SetFilterAsDefault } from '../SetFilterAsDefault/SetFilterAsDefault';

import { JitDivider } from 'components/JitDivider/JitDivider';
import colors from 'themes/colors.module.scss';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  anchorEl: HTMLElement | null
  onClose: () => void
  openPopover: boolean
  savedFilter: ISavedFilter
  editSavedFilter: (savedFilter: ISavedFilter) => void
  setSavedFilterAsDefault: (savedFilter: ISavedFilter) => void
  removeSavedFilter: (savedFilter: ISavedFilter) => void
}

export const SavedFiltersActionsMenu: FC<Props> = ({
  anchorEl,
  onClose,
  openPopover,
  savedFilter,
  editSavedFilter,
  setSavedFilterAsDefault,
  removeSavedFilter,
}) => (

  <Menu
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    elevation={0}
    onClose={onClose}
    open={openPopover}
    PaperProps={{
      className: styles.menuWrapper,
      sx: {
        marginLeft: -2,
        height: 'auto',
        backgroundColor: colors.navyBlue,
      },

    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <EditSavedFilter editSavedFilter={editSavedFilter} onClose={onClose} savedFilter={savedFilter} />

    <SetFilterAsDefault onClose={() => onClose()} savedFilter={savedFilter} setSavedFilterAsDefault={setSavedFilterAsDefault} />

    <JitDivider />

    <DeleteSavedFilter onClose={onClose} removeSavedFilter={removeSavedFilter} savedFilter={savedFilter} />
  </Menu>
);
