import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import { FC, useMemo } from 'react';

import { FindingStatusCell } from '../../../../FindingsPage/components';
import { isoFormatToDateFormat } from '../../../utils';

import { AppFindingDetails } from './AppFindingDetails';
import { FindingDetailsTickets } from './components/FindingDetailsTickets/FindingDetailsTickets';
import { FindingSectionHeader } from './FindingSectionHeader';
import styles from './PipelineFindingDetails.module.scss';

import { Cross } from 'assets';
import { JitCollapsibleText } from 'components/JitCollapsibleText/JitCollapsibleText';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { getSeverityIcon, getSeverityIconFullWidth } from 'components/JitTable/templates/SeverityCell/utils/getSeverityIcon';
import { JitText } from 'components/JitText/JitText';
import { parseFinding } from 'services/FindingsService/utils/parseFindings';
import colors from 'themes/colors.module.scss';
import { IFindingServer, ServerFindingKeys, TicketFinding } from 'types/interfaces';

interface Props {
  finding: IFindingServer;
  handleBack: () => void;
  addTicketToFinding?: (ticket?: TicketFinding) => void;
  variant?: 'dark' | 'light';
  shouldDisplayCreateTicketButton?: boolean;
}

export const PipelineFindingDetails: FC<Props> = ({
  finding, handleBack, variant = 'dark', shouldDisplayCreateTicketButton, addTicketToFinding = () => {},
}) => {
  const format = isoFormatToDateFormat(finding[ServerFindingKeys.created_at] || '') || finding[ServerFindingKeys.created_at];
  const vulnerabilityTypeText = finding[ServerFindingKeys.vulnerability_type].split('_').map((e) => e.charAt(0).toUpperCase()
    + e.slice(1).toLowerCase()).join(' ');
  const statusCell = {
    value: finding[ServerFindingKeys.pipeline_status],
    row: {},
  };
  // @ts-ignore
  const getStatusCell = () => <FindingStatusCell cell={statusCell} />;
  const issueSeverity = useMemo(() => finding[ServerFindingKeys.issue_severity], [finding]);
  const severityIcon = getSeverityIcon(issueSeverity, false, true);
  const details = finding[ServerFindingKeys.issue_text].replace(/(<p>|<\/p>)/g, '');
  const shouldRenderFilename = finding[ServerFindingKeys.code_attributes]?.filename
    && finding[ServerFindingKeys.location]
    && finding[ServerFindingKeys.location_text];

  const isAppFinding = finding[ServerFindingKeys.app_attributes];

  const paperStyle = variant === 'light' ? styles.paperStyleLight : styles.paperStyle;
  const headingContainer = variant === 'light' ? styles.headingContainerLight : styles.headingContainer;
  const backgroundColor = variant === 'light' ? colors.cards : undefined;
  const borderRadius = variant === 'light' ? 6 : undefined;
  const findingsDetailsTextColor = variant === 'light' ? colors.white : colors.lightGray;

  return (
    <Paper
      className={paperStyle}
      data-testid='pipeline-finding-details'
      elevation={0}
      style={{
        backgroundColor,
        borderRadius,
      }}
    >
      <div className={headingContainer}>
        <JitText color={findingsDetailsTextColor} size='m' text='pages.pipelineDetails.findingsDetails.header' />

        <JitIcon data-testid='back-button' icon={Cross} onClick={handleBack} size={25} />
      </div>

      <div style={{ marginTop: '25px' }}>
        <JitText bold size='l' text={finding[ServerFindingKeys.test_name]} />
      </div>

      <div className={styles.iconsContainer}>
        <JitIcon data-testid='finding-detail-severity-icon' icon={severityIcon} size={getSeverityIconFullWidth(issueSeverity)} />

        {getStatusCell()}
      </div>

      <div className={styles.firstDetectedContainer}>
        <JitText className={styles.headerTextWidth} color={colors.lightGray} size='s' text='pages.pipelineDetails.findingsDetails.firstDetected' />

        <JitText size='m' text={format} />
      </div>

      <JitText bold color={colors.lightGray} size='s' style={{ marginTop: '28px' }} text='pages.pipelineDetails.findingsDetails.planContext' />

      <div className={styles.keyValueTextsContainer}>
        <JitText className={styles.headerTextWidth} color={colors.lightGray} size='s' text='pages.pipelineDetails.findingsDetails.vulnerabilityType' />

        <JitText size='m' text={vulnerabilityTypeText} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText className={styles.headerTextWidth} color={colors.lightGray} size='s' text='pages.pipelineDetails.findingsDetails.securityTool' />

        <JitText size='m' text={finding[ServerFindingKeys.control_name]} />
      </div>

      <div className={styles.keyValueTextsContainer}>
        <JitText className={styles.headerTextWidth} color={colors.lightGray} size='s' text='pages.pipelineDetails.findingsDetails.assetType' />

        <JitText size='m' text={finding[ServerFindingKeys.asset_type]} />
      </div>

      {isAppFinding && (
        <AppFindingDetails finding={finding} />
      )}

      {details && (
        <div style={{ marginTop: 30 }}>
          <JitText
            className={styles.headerTextWidth}
            color={colors.lightGray}
            size='s'
            text='pages.pipelineDetails.findingsDetails.details'
          />

          <JitCollapsibleText key={`${finding.id}-details`} text={details} />
        </div>
      )}

      {!isEmpty(finding[ServerFindingKeys.references]) && (
        <>
          <JitText
            className={styles.learnMoreContainer}
            color={colors.lightGray}
            size='s'
            text='pages.pipelineDetails.findingsDetails.learnMoreHeader'
          />

          {finding[ServerFindingKeys.references].map((link, index) => (
            <JitLink
              key={link.url}
              href={link.url}
              rel='noreferrer'
              sx={{
                cursor: 'pointer',
                width: 'fit-content',
              }}
              target='_blank'
            >
              <JitText color={colors.iris} data-testid='reference' text={`Link ${index + 1}`} />
            </JitLink>
          ))}
        </>

      )}

      {shouldRenderFilename
        && (
          <FindingSectionHeader
            bodyText={`${finding[ServerFindingKeys.location_text]}/${finding[ServerFindingKeys.code_attributes]?.filename}`}
            headerClassName={styles.fileNameContainer}
            headerColor={colors.lightGray}
            headerSize='s'
            headerText='pages.pipelineDetails.findingsDetails.fileName'
            linkUrl={finding[ServerFindingKeys.location]}
          />
        )}

      <div className={styles.ticketsContainer}>
        <FindingDetailsTickets
          finding={parseFinding(finding)}
          handleBack={addTicketToFinding}
          shouldDisplayCreateTicketButton={shouldDisplayCreateTicketButton}
        />
      </div>
    </Paper>
  );
};
