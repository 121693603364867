import { FC, useCallback } from 'react';

import styles from './DetailGrid.module.scss';

import { JitText } from 'components/JitText/JitText';

export type Content = {
  title: string | undefined;
  body: string | JSX.Element | undefined
};

interface Props {
  content: Content[]
  gridTemplateColumnsStyle: string;
}

export const DetailGrid: FC<Props> = ({ content, gridTemplateColumnsStyle }) => {
  const Item = useCallback(
    ({ textOrComponent }) => {
      const safeTextOrComponent = textOrComponent || 'N/A';
      if (typeof safeTextOrComponent === 'string') {
        return <JitText text={safeTextOrComponent} />;
      }
      return textOrComponent;
    },
    [],
  );

  const itemList = content.map(({ title, body }) => (
    <div key={title}>
      <JitText muted size='s' text={title} />

      <div className={styles.itemWrapper}>
        <Item textOrComponent={body} />
      </div>
    </div>
  ));

  return (
    <div className={styles.grid} style={{ gridTemplateColumns: gridTemplateColumnsStyle }}>
      {itemList}
    </div>
  );
};
