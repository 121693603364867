import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { GUIDE_ROUTE, TEAM_ROUTE } from './constants';
import styles from './PageHeader.module.scss';

import { ArrowRight, JitLogoIcon } from 'assets';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { UserAvatar } from 'components/Navbar/components';
import colors from 'themes/colors.module.scss';
import { MenuItemKeyType } from 'types/interfaces';
import { IGetTeamResponseItem, ITeam } from 'types/interfaces/Teams/ITeam';
import { NavigationButtons } from 'wrappers/TeamsPortalLayout/components/PageHeader/NavigationButtons';

export enum PageHeaderVariant {
  Default = 'default',
  Welcome = 'welcome',
}

interface Props {
  teams: IGetTeamResponseItem[];
  setCurrentTeam: Dispatch<SetStateAction<IGetTeamResponseItem>>;
  currentTeam?: ITeam | undefined;
  variant?: PageHeaderVariant;
}

export const PageHeader: FC<Props> = ({
  teams,
  setCurrentTeam,
  currentTeam,
  variant = PageHeaderVariant.Default,
}) => {
  const index = teams.findIndex((team) => team.id === currentTeam?.id);
  const [teamIndex, setTeamIndex] = useState<number>(index > -1 ? index : 0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { teamId } = useParams<{ teamId: string }>();

  const menuItems: MenuItemType[] = useMemo(() => teams.map((team) => ({
    itemKey: team.name,
    itemContent: team.name,
    isSelected: team.id === currentTeam?.id,
  })), [currentTeam?.id, teams]);
  useEffect(() => {
    if (teamId) {
      const teamFromId = teams.find((team) => team.id === teamId);
      if (teamFromId && teamId !== currentTeam?.id) {
        setCurrentTeam(teamFromId);
      }
    }
    if (currentTeam && !currentTeam.isActivated && variant !== PageHeaderVariant.Welcome) {
      navigate(GUIDE_ROUTE);
    }
  }, [currentTeam, navigate, pathname, setCurrentTeam, teamId, teams, variant]);

  const onSelect = useCallback(
    ({ itemKey }: { itemKey: MenuItemKeyType }) => {
      const currentTeamIndex = teams.findIndex((team) => team.name === itemKey);
      setTeamIndex(currentTeamIndex);
      const selectedTeam = teams[currentTeamIndex] || teams[0];
      setCurrentTeam(selectedTeam);
      const newPath = `${TEAM_ROUTE}/${selectedTeam.id}`;
      navigate(newPath);
    },
    [navigate, setCurrentTeam, teams],
  );

  const renderTeamDisplay = () => {
    if (currentTeam && teams.length > 1) {
      return (
        <div className={styles.dropdown}>
          <JitDropdownNew
            backgroundColor={colors.navbarBackground}
            displayText={(currentTeam.name || teams[teamIndex]?.name)?.toUpperCase()}
            expandIcon={<JitIcon color={colors.iris} icon={ArrowRight} rotateDegrees={90} size={30} />}
            menuItems={menuItems}
            menuWidth={200}
            noBorder
            onMenuItemClick={onSelect}
            startAdornment={<img alt='team-logo' src={teams[teamIndex]?.imageDetails?.backgroundImageUrl} width={40} />}
          />
        </div>
      );
    }

    if (currentTeam && teams.length === 1) {
      return (
        <div className={styles.tagWrapper}>
          <img alt='team-logo' src={teams[teamIndex].imageDetails?.backgroundImageUrl} width={40} />

          <JitTag borderRadius={0} color={colors.white} text={teams[teamIndex].name.toUpperCase()} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.wrapper} data-testid='teams-portal-header'>
      <div className={styles.left}>
        {variant !== PageHeaderVariant.Welcome && (
          <>
            <div className={styles.text}>
              <JitIcon icon={JitLogoIcon} size={30} />

              <JitText size='xxxl' text='pages.teamsPortal.header.teams' />
            </div>

            {renderTeamDisplay()}
          </>
        )}
      </div>

      {variant !== PageHeaderVariant.Welcome && <NavigationButtons currentTeam={currentTeam} />}

      <div className={styles.user}>
        <UserAvatar
          isCollapsed
          isTeamPortalView
          margin={0}
          padding={0}
          placement='bottom'
          setIsHoverSwitchTenantPopper={() => {
          }}
          shouldShowArrow={false}
          shouldShowLogout
          showBackgroundHover={false}
          size={35}
        />
      </div>
    </div>
  );
};
