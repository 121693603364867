import { useEffect, useCallback, useState, FC, useRef } from 'react';
import ReactFlow, { Background, useNodesState, useEdgesState, BackgroundVariant, ReactFlowInstance } from 'reactflow';

import styles from './Graph.module.scss';
import { CustomNodeType, CustomNodeData, CustomEdgeType } from './types';
import { getLayoutedElements, edgeTypes, nodeTypes } from './utils/visualGraphUtils';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';

interface Props {
  nodes: CustomNodeType[];
  edges: CustomEdgeType[];
  isLoading?: boolean;
}

const GRAPH_LOADING_TIMEOUT_MS = 2000;

export const Graph: FC<Props> = ({ nodes: initialNodes, edges: initialEdges, isLoading: isParentLoading }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isAdjustingLayout, setIsAdjustingLayout] = useState(true);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  const layoutNodesAndEdges = useCallback(async () => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = await getLayoutedElements(initialNodes, initialEdges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    // In 'Happy flow', we'll set this state to false as part of `onNodesChange`.
    // but this handles the case when the graph is empty and the event is not triggered
    setTimeout(() => setIsAdjustingLayout(false), GRAPH_LOADING_TIMEOUT_MS);
  }, [initialNodes, initialEdges, setNodes, setEdges]);

  useEffect(() => {
    layoutNodesAndEdges();
  }, [isParentLoading, layoutNodesAndEdges]);

  const isLoading = isAdjustingLayout || isParentLoading;
  const fitView = useCallback(() => {
    reactFlowInstance?.current?.fitView({ padding: 0.3 });
  }, []);

  useEffect(() => {
    if (isLoading) return;
    // Reload view after all nodes and edges are loaded
    fitView();
  }, [isLoading, nodes, edges, fitView]);

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <CircularLoadingSpinner size='large' />
        </div>
      )}

      <ReactFlow
        edges={isParentLoading ? [] : edges}
        edgeTypes={edgeTypes}
        fitView
        nodes={isParentLoading ? [] : nodes}
        nodeTypes={nodeTypes}
        onEdgesChange={onEdgesChange}
        onInit={(instance) => {
          reactFlowInstance.current = instance;
        }}
        onNodesChange={(changes) => {
          onNodesChange(changes);
          setIsAdjustingLayout(false);
        }}
        proOptions={{ hideAttribution: true }}
      >
        <Background color='gray' gap={16} variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </div>
  );
};
