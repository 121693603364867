import { FC } from 'react';

import styles from './AssetType.module.scss';
import { getAssetTypeDisplayName } from './utils/getAssetTypeDisplayName';
import { getIconByVendor } from './utils/getIconByVendor';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';

interface Props {
  vendor?: string;
  assetName?: string;
}

export const AssetType: FC<Props> = ({ vendor, assetName }) => {
  const text = getAssetTypeDisplayName(assetName || '');
  const icon = getIconByVendor(vendor, undefined, true);

  return (
    <div
      className={styles.wrapper}
    >
      {icon && (
        <JitIcon
          className={styles.icon}
          icon={icon}
        />
      )}

      {assetName && (
        <TooltipOnlyOnOverflow text={text} />
      )}

    </div>
  );
};
