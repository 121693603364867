import { FC, useCallback, useMemo } from 'react';

import { IntegrationLink } from './components/IntegrationLink';
import styles from './PlanItemDetailsIntegrations.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import colors from 'themes/colors.module.scss';
import { AssetType, SCM_VENDORS, Vendor } from 'types/enums';
import { ISvg } from 'types/interfaces';
import { vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { useAssetTypeToVendor } from 'utils/hooks/useAssetTypeToVendor';

interface Integration {
  vendor?: Vendor;
  icon: FC<ISvg> | null;
}

interface Props {
  assetTypes: AssetType[];
}

export const PlanItemDetailsIntegrations: FC<Props> = ({ assetTypes }) => {
  const { isVendorProperlyIntegrated } = useAssetsContext();
  const { isScmVendorIntegrated } = useTenantContext();
  const { getIconByAssetType, getVendorByAssetType } = useAssetTypeToVendor();

  const isProperlyIntegrated = useCallback((vendor: Vendor) => {
    if (SCM_VENDORS.includes(vendor)) {
      return isScmVendorIntegrated(vendor);
    }

    return isVendorProperlyIntegrated(vendor);
  }, [isScmVendorIntegrated, isVendorProperlyIntegrated]);

  const getCurrentIntegrationTextChildren = useCallback(
    (vendor?: Vendor) => {
      if (!vendor) return null;

      const isIntegrated = isProperlyIntegrated(vendor);

      if (!isIntegrated) {
        return (
          <IntegrationLink vendor={vendor} />
        );
      }

      return (
        <JitText
          color={colors.successGreen}
          data-testid='integration-link'
          text='pages.plan.itemDetails.connected'
        />
      );
    },
    [isProperlyIntegrated],
  );

  const isVendorRequireIntegration = (vendor?: Vendor) => vendor && vendorsWithIntegration.includes(vendor);

  const renderIntegration = (integration: Integration) => (integration.icon ? (
    <JitIcon
      icon={integration.icon}
      size='1.8em'
      withBackSquare={{
        borderRadius: '50%',
        backgroundColor: colors.cards,
      }}
    />
  )
    : (
      <JitText
        bold
        text={integration.vendor?.toLocaleUpperCase()}
      />
    ));

  const integrations = useMemo(() => {
    const vendorsIntegrations: Integration[] = [];
    const vendors = new Set();

    assetTypes.forEach((assetType) => {
      const vendor = getVendorByAssetType(assetType);
      if (!vendors.has(vendor)) {
        vendors.add(vendor);
        if (isVendorRequireIntegration(vendor)) {
          vendorsIntegrations.push({
            vendor,
            icon: getIconByAssetType(assetType),
          });
        }
      }
    });
    return vendorsIntegrations;
  }, [assetTypes, getIconByAssetType, getVendorByAssetType]);

  if (!integrations.length) return null;

  return (
    <div className={styles.wrapper}>
      {integrations.map((integration) => (
        <div
          key={integration.vendor}
          className={styles.integration}
        >
          {renderIntegration(integration)}

          <div>
            {getCurrentIntegrationTextChildren(integration.vendor)}
          </div>
        </div>
      ))}
    </div>
  );
};
