import { FC, useState, useMemo } from 'react';
import { NodeProps, Handle, Position } from 'reactflow';

import styles from './CustomNode.module.scss';
import { CustomNodeData } from './types';
import { getEntityPriorityFactors } from './utils/graphDataUtils';
import { getExternalLink, getVendorFactors, getVendorFormattedFactorText, getVendorIcon } from './utils/vendorUtils';
import { EXTRA_HEIGHT_NODE_WITH_FACTOR, NODE_WIDTH, NODE_HEIGHT } from './utils/visualGraphUtils';

import { Cross } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useAddRemoveFactors } from 'context/GraphContext/hooks/useAddRemoveFactors';
import colors from 'themes/colors.module.scss';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

export const CustomNode: FC<NodeProps<CustomNodeData>> = ({ data }) => {
  const { icon, type, name, factors, entity } = data;
  const { removeFactors } = useAddRemoveFactors();
  const { allPriorityFactors } = useGraphContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const entityFactors = useMemo(() => getEntityPriorityFactors(entity), [entity]);

  const filteredFactors = (factors?.filter((factor) => entityFactors.includes(factor))
    || []).map((factor) => allPriorityFactors.find((f) => f.key === factor)).filter((factor): factor is NonNullable<typeof factor> => factor
    !== undefined);

  const hasFactors = filteredFactors.length > 0;

  const borderColor = hasFactors ? colors.criticalPriority : '#0079f7';

  const handleRemoveFactor = async (factor: IPriorityFactor) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await removeFactors(entity, [factor]);
    setIsSubmitting(false);
  };

  const externalLink = getExternalLink(data);

  const vendorFactors = getVendorFactors(data);

  const vendorIcon = getVendorIcon(data);

  const getFactorStyle = (index: number) => {
    const bottom = -(EXTRA_HEIGHT_NODE_WITH_FACTOR + 6) * (index + 1) + 8;
    return {
      minWidth: NODE_WIDTH * 0.9,
      left: NODE_WIDTH * 0.05,
      borderColor,
      bottom,
    };
  };

  return (
    <div
      className={`${styles.customNode} ${hasFactors ? styles.critical : ''}`}
      style={{
        borderColor,
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
      }}
    >
      <div className={styles.iconContainer} style={{ backgroundColor: icon.bgColor }}>
        <JitIcon icon={icon.icon} size={16} />
      </div>

      <div className={styles.textContainer} style={{ maxWidth: NODE_WIDTH }}>
        <TooltipOnlyOnOverflow bold noWrap overflowHiddenEllipsis size='s' text={type} />

        <TooltipOnlyOnOverflow className={styles.subText} muted noWrap overflowHiddenEllipsis size='xs' text={name} />
      </div>

      {hasFactors && (
        <>
          {filteredFactors.map((factor, index) => (
            <div
              key={factor.displayName}
              className={styles.factorsBox}
              style={getFactorStyle(index)}
            >

              <div className={styles.factorWrapper}>
                <div className={styles.factorTextWrapper}>
                  <JitText muted noWrap size='s' text={factor.displayName} />
                </div>

                {factor.group.isEditable && (
                  <JitTooltip followCursor={false} placement='top' title='pages.findings.findingDetails.priority.addRemoveFactors.removeIconTooltip'>
                    <JitIcon
                      color={colors.cards}
                      icon={Cross}
                      onClick={() => handleRemoveFactor(factor)}
                      size={10}
                      withBackSquare={{
                        backgroundColor: colors.iris,
                        borderRadius: '50%',
                        height: 14,
                        width: 14,
                        borderColor: 'transparent',
                      }}
                    />
                  </JitTooltip>
                )}
              </div>
            </div>
          ))}
        </>
      )}

      {!!vendorFactors.length && (
        <>
          {vendorFactors.map((factor, index) => (
            <div
              key={factor}
              className={styles.factorsBox}
              style={getFactorStyle(index)}
            >

              <div className={styles.vendorFactorsWrapper}>
                {vendorIcon && <JitIcon className={styles.vendorIcon} icon={vendorIcon} size={16} />}

                <JitText muted noWrap size='s' text={getVendorFormattedFactorText(factor)} />

              </div>
            </div>
          ))}
        </>
      )}

      {externalLink && (
        <div className={styles.externalLink}>
          <JitExternalLink
            analytics={{
              action: `open-external-link-${externalLink.split('?')[0]}`,
              params: { url: externalLink },
            }}
            className={styles.externalLink}
            href={externalLink}
            size='s'
            text=''
          />
        </div>
      )}

      <Handle position={Position.Left} style={{ visibility: 'hidden' }} type='target' />

      <Handle position={Position.Right} style={{ visibility: 'hidden' }} type='source' />
    </div>
  );
};
