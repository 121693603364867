import { FC } from 'react';

import styles from './OpenFixPRToast.module.scss';

import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  subtitle: string
  link?: string
}

export const OpenFixPRToast: FC<Props> = ({ subtitle, link }) => (
  <div className={styles.root}>
    <JitText color={colors.cards} text={subtitle} />

    {link && (<JitExternalLink href={link} text='openFixPR.openingToast.openingCompleted.link' textColor={colors.blue} />)}
  </div>
);
