import { difference } from 'lodash';
import { FC, useState } from 'react';

import styles from './AddRemovePopover.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useAddRemoveFactors } from 'context/GraphContext/hooks/useAddRemoveFactors';
import { PopoverEditMode } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverEditMode';
import { PopoverHeader } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverHeader';
import { PopoverViewMode } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverViewMode';
import { IFinding, ActionButtonVariant } from 'types/interfaces';
import { IPriorityFactor } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  finding: IFinding;
  onClose: () => void;
}

export const AddRemovePopover: FC<Props> = ({ finding, onClose }) => {
  const { allPriorityFactors, isLoadingPriorityFactors } = useGraphContext();
  const [updatedPriorityFactors, setUpdatedPriorityFactors] = useState<string[]>(finding.priorityFactors || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addFactors, removeFactors } = useAddRemoveFactors();
  const [isInEditMode, setIsInEditMode] = useState(false);

  const enrichedOriginalFactors = updatedPriorityFactors.map((factorName) => allPriorityFactors.find((factor) => factor.key
    === factorName)).filter((factor) => factor) as IPriorityFactor[];

  const priorityScoreToDisplay = isInEditMode
    ? (allPriorityFactors.reduce((acc, factor) => acc + (updatedPriorityFactors.includes(factor.key) ? factor!.defaultWeight : 0), 0)
      || finding.priorityScore || 0)
    : (finding.priorityScore || 0);

  const toggleFactor = (factor: string) => {
    if (updatedPriorityFactors.includes(factor)) {
      setUpdatedPriorityFactors((prevState) => prevState.filter((f) => f !== factor));
    } else {
      setUpdatedPriorityFactors((prevState) => [...prevState, factor]);
    }
  };
  const addedFactors = enrichedOriginalFactors.filter((factor) => !finding.priorityFactors?.includes(factor.key));
  const removedFactors = difference(finding.priorityFactors || [], updatedPriorityFactors).map((factor) => allPriorityFactors.find((f) => f.key
    === factor)!);

  const hasFactorsChanged = addedFactors.length > 0 || removedFactors.length > 0;

  const submitFactors = async () => {
    setIsSubmitting(true);
    if (addedFactors.length > 0) {
      await addFactors(finding, addedFactors);
    }
    if (removedFactors.length > 0) {
      await removeFactors(finding, removedFactors);
    }
    setIsSubmitting(false);
  };

  let body;
  if (isLoadingPriorityFactors) {
    body = (
      <div className={styles.spinnerWrapper}>
        <CircularLoadingSpinner size='large' />
      </div>
    );
  } else if (isInEditMode) {
    body = (
      <>
        <PopoverEditMode selectedFactors={updatedPriorityFactors} toggleFactor={toggleFactor} />

        <div className={styles.submitButtonWrapper} data-testid='ApplyButton'>
          <JitActionButton actionButton={{
            handleClick: submitFactors,
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.apply',
            variant: ActionButtonVariant.PRIMARY,
            disabled: isLoadingPriorityFactors || !hasFactorsChanged,
            isLoading: isSubmitting,
          }}
          />
        </div>
      </>
    );
  } else {
    body = <PopoverViewMode selectedFactors={enrichedOriginalFactors} setIsInEditMode={setIsInEditMode} />;
  }

  return (
    <div className={styles.wrapper} data-testId='AddRemovePopover'>
      <PopoverHeader
        isInEditMode={isInEditMode}
        onClose={onClose}
        priorityScore={priorityScoreToDisplay}
        setIsInEditMode={setIsInEditMode}
      />

      <JitDivider />

      <div className={styles.body}>
        {body}
      </div>
    </div>
  );
};
